import { useEffect, useState } from 'react'
import { pushError } from './store/actions'
import { Auth } from '@aws-amplify/auth'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { useDispatch } from 'react-redux'
import {
  PostSearchRequest,
  PostSearchResponse,
  GetMessageResponse,
  GetMessageArtifactsResponse,
  PostFilesLogRequest,
  PostRequestUploadRequest,
  PostRequestUploadResponse,
  PostFilesLogResponse,
} from '@amzn/card-dashboard-backend/src/types'
import {
  BusinessChannelAndMarketplace,
  UploadFileRequest,
  UploadFileResponse,
  PresignedURLRequest,
  PresignedURLResponse,
} from './Routes/SpreadsheetUpload/UploadInfoForm'
import {
  CustomerAndAddressRequest,
  CustomerAndAddressResponse,
  PreDefinedValues,
} from './Routes/CreateCustomer/CreateCustomer'

import {
    PaginationInfo,
    ViewEventLogRequest,
    ViewEventLogResponse,
} from './Routes/EventLog/AuditSearch'
import {
  GetCimarronPersistedInformationRequest,
  GetCimarronPersistedInformationResponse,
} from './Routes/Message/BillingHubRequest/HeaderCimarron'
import {
  ViewMigrationEntitiesResponse,
  ViewMigrationEntitiesRequest
} from './Routes/EntityStatusTracker/RequestResponseConstants'
import { ValidateAuthTokenResponse } from './Common/Authentication'
import { URLS } from './Common/urls'
import { PrintInvoiceRequest, PrintInvoiceResponse, SubmitInvoiceRequest, SubmitInvoiceResponse } from './Routes/Message/ProcessLog'
import { ActivateDeactivateCustomerRequest, ActivateDeactivateCustomerResponse } from './Routes/CustomerDetails/Customer'
import { DeactivateAddressRequest, DeactivateAddressResponse } from './Routes/CustomerDetails/Address/DeactivateAddress'
import { UpdateAddressRequest, UpdateAddressResponse } from './Routes/CustomerDetails/Address/UpdateAddress'
import { SetAsPrimaryAddressResponse } from './Routes/CustomerDetails/Address/SetAsPrimaryAddress'
import { GetCustomerRequest, GetCustomerResponse } from './types'

export interface ErrorObject {
  unauthorized?: boolean
}

const authenticatedFetch = async (
  url: string,
  options?: any
): Promise<{
  unauthorized?: boolean
  error?: boolean
  data?: any
}> => {
  const session = await getSession()

  const res = await fetch(url, {
    ...(options || {}),
    headers: {
      authentication: session.getIdToken().getJwtToken() as string,
      'Content-Type': 'application/json',
    },
  })
  const json = await res.json()

  if (!res.ok) {
    if (res.status === 401) return { unauthorized: true, error: true }
    else
      return { data: json, error: true }
  } else
      return { data: json }
  
}

export const useFetch = <T>(
  url: string,
  options?: {
    skip?: boolean
    [key: string]: any
  }
): { loading: boolean; error?: ErrorObject; data?: T } => {
  const [data, setData] = useState(undefined)
  const [error, setError] = useState<ErrorObject | undefined>(undefined)
  const [loading, setLoading] = useState(true)

  const dispatch = useDispatch()

  const optionsString = JSON.stringify(options || {})

  useEffect(() => {
    const options = JSON.parse(optionsString)

    // if skip is true, don't attempt to get any data
    if (options?.skip) {
      setLoading(false)
      return
    }

    const fetchData = async () => {
      try {
        setData(undefined)
        setLoading(true)
        setError(undefined)

        const { data, unauthorized, error } = await authenticatedFetch(
          url,
          options
        )

        if (unauthorized) setError({ unauthorized: true })
        else if (error) setError({})
        else setData(data)

        setLoading(false)
      } catch (error) {
        setError({}) // makes error truthy even if it does't specify a cause
        setLoading(false)
        dispatch(
          pushError(
            `The API had trouble processing some parts of this request.`
          )
        )
        console.error({ error })
      }
    }
    fetchData()
  }, [url, dispatch, optionsString])
  return { data, error, loading }
}

export const getSession = async (): Promise<CognitoUserSession> => {
  const session = await Auth.currentSession()

  // amplify doesn't reliably refresh tokens so manually check and refresh it.
  // if the token expires within 1 minute from now, refresh it
  if (session.getIdToken().getExpiration() - Date.now() / 1000 < 60) {
    const user = await Auth.currentUserPoolUser()
    return new Promise((resolve, reject) =>
      user.refreshSession(session.getRefreshToken(), (error: any, data: any) =>
        error ? reject(error) : resolve(data)
      )
    )
  } else {
    return session
  }
}

export const useMessage = (messageId: string, stage: string) =>
  useFetch<GetMessageResponse>(`/api/message/${stage}/${messageId}`)

export const useMessageArtifacts = (
  messageId: string,
  stage: string,
  trxNumber: string,
  skip?: boolean
) =>
  useFetch<GetMessageArtifactsResponse>(
    `/api/message/${stage}/${messageId}/${trxNumber}/artifacts`,
    { skip }
  )

export const useSearch = (params: PostSearchRequest) =>
  useFetch<PostSearchResponse>(`/api/search`, {
    method: 'POST',
    body: JSON.stringify(params),
  })

export const useSearchAudit = (
  params: any
): Promise<{ data?: ViewEventLogResponse; error?: boolean }> => {
  const paginationInfo: PaginationInfo = {
    start: params.start!,
    limit: params.limit!,
    sortBy: params.sortBy!,
    sortAscending: params.sortAscending!,
  }

  const viewRequest: ViewEventLogRequest = {
    query: params.query!,
    messageType: params.messageType,
    status: params.status,
    startDate: params.after,
    endDate: params.before,
    PaginationInfo: paginationInfo,
      startKeyMap: params.startKeyMap
  }
  const newParams = viewRequest
  return authenticatedFetch(
     URLS.ViewEventLog,
    {
      method: 'POST',
      body: JSON.stringify(newParams),
    }
  )
}

export const useWebBillingFilesLog = (params: PostFilesLogRequest) =>
  useFetch<PostFilesLogResponse>('/api/webbilling/filesLog', {
    method: 'POST',
    body: JSON.stringify(params),
  })

export const webBillingRequestUpload = (
  params: PostRequestUploadRequest
): Promise<{ data?: PostRequestUploadResponse; error?: boolean }> =>
  authenticatedFetch('/api/webbilling/requestUpload', {
    method: 'POST',
    body: JSON.stringify(params),
  })

export const createCustomer = (
  params: CustomerAndAddressRequest
): Promise<{ data?: CustomerAndAddressResponse; error?: boolean }> =>
  authenticatedFetch(
    URLS.CreateCustomer,
    {
      method: 'POST',
      body: JSON.stringify(params),
    }
  )

export const addAddressToCustomer = (
  params: CustomerAndAddressRequest
): Promise<{ data?: CustomerAndAddressResponse; error?: boolean }> =>
  authenticatedFetch(
    URLS.AddAddressToCustomer,
    {
      method: 'POST',
      body: JSON.stringify(params),
    }
  )

export const invoiceUploadFile = (
  params: UploadFileRequest
): Promise<{ data?: UploadFileResponse; error?: boolean }> =>
  authenticatedFetch(
    URLS.InvoiceUpload,
    {
      method: 'POST',
      body: JSON.stringify(params),
    }
  )

export const getAllPreDefinedValues = (): Promise<{
  data?: PreDefinedValues
  error?: boolean
}> =>
  authenticatedFetch(
    URLS.GetAllPreDefinedValues,
    {
      method: 'GET',
    }
  )

export const getCustomerDetailsViewActivity = (
  params: GetCustomerRequest
): Promise<{ data?: GetCustomerResponse; error?: boolean }> =>
  authenticatedFetch(
    URLS.GetCustomerDetailsViewActivity + params.trackingId,
    {
      method: 'GET',
    }
  )

export const getS3PresignedURL = (
  params: PresignedURLRequest
): Promise<{ data?: PresignedURLResponse; error?: boolean }> =>
  authenticatedFetch(
      URLS.GetS3PresignedURL,
    {
      method: 'POST',
      body: JSON.stringify(params),
    }
  )

export const getAllBusinessChannelsAndMarketplaces = (): Promise<{
  data?: BusinessChannelAndMarketplace
  error?: boolean
}> =>
  authenticatedFetch(
    URLS.GetAllBusinessChannelsAndMarketplaces,
    {
      method: 'GET',
    }
  )

export const getCimarronPersistedInformation = (
  params: GetCimarronPersistedInformationRequest,
  stage: string
): Promise<{
  data?: GetCimarronPersistedInformationResponse
  error?: boolean
}> =>
  
  authenticatedFetch(
    stage === 'beta' ? URLS.GetCimarronPersistedInformationBeta + params.billingHubMessageId:
    stage === 'gamma' ? URLS.GetCimarronPersistedInformationGamma + params.billingHubMessageId:
    URLS.GetCimarronPersistedInformationProd + params.billingHubMessageId,
    {
      method: 'GET'
    }
  )

export const getUserPermission = (): Promise<{
  data?: ValidateAuthTokenResponse
  error?: boolean
}> =>
  authenticatedFetch(
    URLS.GetUserPermission,
    {
      method: 'GET',
    }
  )

export const updateCustomer = (
  params: CustomerAndAddressRequest
): Promise<{ data?: CustomerAndAddressResponse; error?: boolean }> =>
  authenticatedFetch(
    URLS.UpdateCustomer,
    {
      method: 'POST',
      body: JSON.stringify(params),
    }
  )

export const deactivateCustomer = (
  params: ActivateDeactivateCustomerRequest
): Promise<{ data?: ActivateDeactivateCustomerResponse; error?: boolean }> =>
  authenticatedFetch(
    URLS.DeactivateCustomer,
    {
      method: 'POST',
      body: JSON.stringify(params),
    }
  )

export const activateCustomer = (
  params: ActivateDeactivateCustomerRequest
): Promise<{ data?: ActivateDeactivateCustomerResponse; error?: boolean }> =>
  authenticatedFetch(
    URLS.ActivateCustomer,
    {
      method: 'POST',
      body: JSON.stringify(params),
    }
  )

  export const deactivateAddress = (
  params: DeactivateAddressRequest
): Promise<{ data?: DeactivateAddressResponse; error?: boolean }> =>
  authenticatedFetch(
    URLS.DeactivateAddress,
    {
      method: 'POST',
      body: JSON.stringify(params),
    }
  )

  export const updateAddress = (
  params: UpdateAddressRequest
): Promise<{ data?: UpdateAddressResponse; error?: boolean }> =>
  authenticatedFetch(
    URLS.UpdateAddress,
    {
      method: 'POST',
      body: JSON.stringify(params),
    }
  )

  export const updatePrimaryAddress = (
    params: CustomerAndAddressRequest
  ): Promise<{ data?: SetAsPrimaryAddressResponse; error?: boolean }> =>
    authenticatedFetch(
      URLS.UpdatePrimaryAddress,
      {
        method: 'POST',
        body: JSON.stringify(params),
      }
    )

    export const useSearchMigration = (
      params: any
    ): Promise<{ data?: ViewMigrationEntitiesResponse; error?: boolean }> => {
      const paginationInfo: PaginationInfo = {
        start: params.start!,
        limit: params.limit!,
        sortBy: params.sortBy!,
        sortAscending: params.sortAscending!,
      }
      const viewRequest: ViewMigrationEntitiesRequest= {
        ofaEntityId: params.ofaEntityId,
        entityType: params.entityType,
        migrationStatus: params.migrationStatus!,
        startDate: params.before!,
        endDate: params.after!,
        PaginationInfo: paginationInfo!,
      }
      const newParams = viewRequest
      return authenticatedFetch(
        URLS.GetMigrationEntityList,
        {
          method: 'POST',
          body: JSON.stringify(newParams),
        }
      )
    }

    export const useSubmitInvoice = (
        params: SubmitInvoiceRequest
      ): Promise<{ data?:SubmitInvoiceResponse; error?: boolean }> => {

        const SubmitInvoiceRequestParams: SubmitInvoiceRequest= {
          billingHubMessageId: params.billingHubMessageId,
          clientId:params.clientId,
          marketplaceId: params.marketplaceId,
          batchSourceName: params.batchSourceName,
          sellerOfRecord: params.sellerOfRecord
        }
        return authenticatedFetch(
          URLS.SubmitInvoice,
          {
            method: 'POST',
            body: JSON.stringify(SubmitInvoiceRequestParams),
          }
        )
    }

    
    export const usePrintInvoice = (
      params: PrintInvoiceRequest
    ): Promise<{ data?:PrintInvoiceResponse; error?: boolean }> => {

      const PrintInvoiceRequestParams: PrintInvoiceRequest= {
        billingHubMessageId: params.billingHubMessageId,
        clientId: params.clientId,
        marketplaceId: params.marketplaceId,
        batchSourceName: params.batchSourceName,
        ofaInvoiceNumber: params.ofaInvoiceNumber,
        sellerOfRecord: params.sellerOfRecord
      }
      return authenticatedFetch(
        URLS.PrintInvoice,
        {
          method: 'POST',
          body: JSON.stringify(PrintInvoiceRequestParams),
        }
      )

    }
  