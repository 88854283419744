export const URLS = {
    ViewEventLog : "https://api-pre-prod-v1.card.fintech.amazon.dev/v2/eventLog",
    CreateCustomer: "https://api-pre-prod-v1.card.fintech.amazon.dev/v2/customers",
    DeactivateCustomer: "https://api-pre-prod-v1.card.fintech.amazon.dev/v2/deactivateCustomer",
    ActivateCustomer: "https://api-pre-prod-v1.card.fintech.amazon.dev/v2/activateCustomer",
    AddAddressToCustomer: "https://api-pre-prod-v1.card.fintech.amazon.dev/v2/addAddressToCustomer",
    DeactivateAddress: "https://api-pre-prod-v1.card.fintech.amazon.dev/v2/deactivateAddress",
    UpdateAddress: "https://api-pre-prod-v1.card.fintech.amazon.dev/v2/updateAddress",
    InvoiceUpload: "https://api-pre-prod-v1.card.fintech.amazon.dev/v2/invoiceUpload",
    GetAllPreDefinedValues: "https://api-pre-prod-v1.card.fintech.amazon.dev/v2/predefinedValues",
    GetCustomerDetailsViewActivity: "https://api-pre-prod-v1.card.fintech.amazon.dev/v2/getCustomerDetailsViewActivity/",
    GetS3PresignedURL: "https://fvd8mffbwa.execute-api.us-east-1.amazonaws.com/prod/PresignedPOSTS3",
    GetAllBusinessChannelsAndMarketplaces: "https://api-pre-prod-v1.card.fintech.amazon.dev/v2/businessChannelAndMarketplaces",
    GetCimarronPersistedInformationGamma: "https://api-pre-prod-v1.card.fintech.amazon.dev/v2/cimarronPersistedInformation/",
    GetCimarronPersistedInformationBeta: "https://api-devo-v1.card.fintech.amazon.dev/v2/cimarronPersistedInformation/",
    GetCimarronPersistedInformationProd: "https://api-prod-v1.card.fintech.amazon.dev/v2/cimarronPersistedInformation/",
    GetUserPermission: "https://api-devo-v1.card.fintech.amazon.dev/v2/userPermissionSet",
    UpdateCustomer: "https://api-pre-prod-v1.card.fintech.amazon.dev/v2/updateCustomer",
    UpdatePrimaryAddress: "https://api-pre-prod-v1.card.fintech.amazon.dev/v2/updatePrimaryAddress",
    GetMigrationEntityList: "https://api-devo-v1.card.fintech.amazon.dev/v2/getMigrationEntityList",
    SubmitInvoice: "https://api-pre-prod-v1.card.fintech.amazon.dev/v2/submitInvoice",
    PrintInvoice: "https://api-pre-prod-v1.card.fintech.amazon.dev/v2/printInvoice",
}
