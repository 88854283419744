// Add Wiki link on each render
import React from 'react'
import { Link } from 'react-router-dom'

import './Tabs.scss'
import {isProd} from "../utils";

export default () => {
  return (
    <div className="tabs-container">
      <Link to="/" className="tabs-tab">
        Search
      </Link>
        {!isProd && <Link to="/createcustomer" className="tabs-tab">
        Create Customer
      </Link>}
        {!isProd &&
            <Link to="/spreadsheet-upload" className="tabs-tab">
        Spreadsheet Upload
      </Link> }
        {!isProd &&
         <Link to="/audit" className="tabs-tab">
        Event Log
      </Link> }
      {!isProd &&
         <Link to = "/entity-status" className="tabs-tab">
        Migration Search Engine
      </Link>}
    </div>
  )
}
