import React from 'react'
import Message from './Message'
import { Switch, Route } from 'react-router-dom'
import Alert from '@amzn/awsui-components-react/polaris/alert'
import FilesLog from './WebBilling/FilesLog'
import Wizard from './WebBilling/Wizard'
import CustomerDetailsPage from './CustomerDetails'
import CreateCustomerPending from './CreateCustomer/CreateCustomerPending'
import MessageSearch from './Search/MessageSearch'
import AuditSearch from './EventLog/AuditSearch'
import ConfirmationPage from './SpreadsheetUpload/ConfirmationPage'
import SpreadsheetUpload from './SpreadsheetUpload'
import CreateCustomerPage from './CreateCustomer'
import EntitySearchPage from './EntityStatusTracker';
import {isProd} from "../utils";

const Routes = () => (
  <Switch>
    <Route exact path="/" component={MessageSearch} />
    <Route exact path="/billinghub" component={MessageSearch} />
    <Route path="/billinghub/message/:stage/:messageId" component={Message} />
    <Route path="/billinghub/search" component={MessageSearch} />
    <Route path="/webbilling/filesLog" component={FilesLog} />
    <Route path="/webbilling/wizard" component={Wizard} />
    <Route path="/createcustomer" component={!isProd ? CreateCustomerPage : NotFound} />
    <Route path="/createcustomerpending/:trackingId" component={CreateCustomerPending} />
    <Route path="/customer/:trackingId" component={!isProd ? CustomerDetailsPage : NotFound} />
    <Route
      path="/spreadsheet-upload/confirmation/:trackingId"
      component={ConfirmationPage}
    />
    <Route path="/spreadsheet-upload" component={SpreadsheetUpload} />
    <Route path="/entity-status" component={EntitySearchPage} />
    <Route path="/audit" component={AuditSearch} />
    <Route path="/audit/:query" component={AuditSearch} />
    <Route path="/audit/:query/:stage" component={AuditSearch} />
    <Route path="*" component={NotFound} />
  </Switch>
)

const NotFound = () => (
  <div className="awsui-util-p-m">
    <Alert type="error" header="Page Not Found">
      The page you were looking for was not found. If you continue to get this error, please submit
      a ticket.
    </Alert>
  </div>
)

export default Routes